<script setup lang="ts"></script>

<template>
  <div class="open-indicator"></div>
</template>

<style scoped>
/* TODO MOVE TO GLOBAL SCSS */
.open-indicator {
  width: 12px;
  height: 12px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSI2Ljc4OCIgdmlld0JveD0iMTEuNjI1IDI3LjkyNCAyMCA2Ljc4OCI+PHBhdGggZmlsbD0iI0Y5RjlGOSIgZD0iTTMyIDYwSDJNMiAwaDMwIi8+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMjIuMjAzIDI4LjkyNGwtNC43ODkgNC43ODgtNC43ODktNC43ODgiLz48L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: left;
}
</style>
