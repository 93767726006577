<script setup lang="ts">
import VueSelect from 'vue-select'
import DeselectComponent from './SearchSelect/Deselect.vue'
import OpenIndicator from './SearchSelect/OpenIndicator.vue'
import { computed, onMounted, type PropType, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import { useNuxtApp } from '#app'

const { $global, $_, $store } = useNuxtApp()

import 'vue-select/dist/vue-select.css'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  config: {
    type: Object as PropType<{
      action?: Function
      fetch?: { url: string; params?: Object }
      params?: Object
    }>,
    default: () => {},
  },
  transform: {
    type: Function,
    default: option => ({
      value: option.id,
      label: option.name,
    }),
  },
  ariaLabel: {
    type: String,
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  selectFirst: {
    type: Boolean,
    default: true,
  },
  emitOnSelectFirst: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets HTML placeholder of the input
   */
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [Number, String, Object, Array],
    default() {
      return []
    },
  },
  taggable: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Function,
    default() {
      return true
    },
  },
  debounced: {
    type: Boolean,
    default: false,
  },
  createOption: {
    type: Function,
    default(newOption) {
      if (this.optionList && typeof this.optionList[0] === 'object') {
        newOption = { [this.label]: newOption }
      }

      if (this.$emit) this.$emit('option:created', newOption)
      return newOption
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  hideSelected: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Function,
    default(option) {
      return true
    },
  },
  additionalParams: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const loadingFromUrl = ref<boolean>(false)
const hasDebouncedFetch = ref<boolean>(props.debounced)

// Emits
const emit = defineEmits<{
  (event: 'update:modelValue', data): void
  (event: 'input'): void
  (event: 'deselected'): void
  (event: 'search', data): void
}>()

const selected = ref(null)
const Deselect = props.clearable ? DeselectComponent : null

const callConfigAction = async (loading, params) => {
  try {
    loading(true)
    loadingFromUrl.value = true

    if (props.config.fetch.url) {
      await $store.dispatch(props.config.fetch.url, params)
    } else {
      await props.config.action(params)
    }
  } finally {
    loadingFromUrl.value = false
    loading(false)
  }
}

const callDebouncedConfigAction = debounce(async (loading, params) => {
  await callConfigAction(loading, params)
}, 500)

const fetchOptions = async (search, loading, prefetch = undefined) => {
  // When search is empty, skip => when null, load all options

  // Pinia action - called "action"
  if (typeof props.config?.action === 'function') {
    loading(true)

    let params = { ...props.config.params, ...props.additionalParams, search }

    if (prefetch) {
      // Single id convert to array
      if (!Array.isArray(prefetch)) {
        prefetch = [prefetch]
      }

      params.selectedIds = prefetch
    }

    // If there is a fetch url fetch data
    if (props.config.action) {
      if (hasDebouncedFetch.value) {
        await callDebouncedConfigAction(loading, params)
      } else {
        await callConfigAction(loading, params)
      }
    }
  }

  // Vuex action - called "fetch"
  if (typeof props.config.fetch === 'object' && search !== '') {
    loading(true)

    let params = { search }

    // If there is any parameter, add it to request
    if (typeof props.config.fetch.params === 'object') {
      params = { ...params, ...props.config.fetch.params }
    }

    if (prefetch) {
      // Single id convert to array
      if (!Array.isArray(prefetch)) {
        prefetch = [prefetch]
      }

      params.selectedIds = prefetch
    }

    // If there is a fetch url fetch data
    if (props.config.fetch.url) {
      if (hasDebouncedFetch.value) {
        await callDebouncedConfigAction(loading, params)
      } else {
        await callConfigAction(loading, params)
      }
    }
  }
}

const setSelected = (value, quietly = false) => {
  if (value === null) {
    selected.value = null
    emitValue(quietly)
    return
  }

  nextTick(() => {
    if (props.multiple && value && typeof value === 'object') {
      const found = Object.keys(value)?.map(key => {
        return getOptions.value.find(o => o.value === value[key])
      })
      if (found) {
        if (Array.isArray(selected.value)) {
          selected.value = [...selected.value, value]
        } else {
          selected.value = [value]
        }
      }
    } else {
      const searchedValue = value?.value ? value.value : value
      value = getOptions.value.find(o => o.value === searchedValue)

      selected.value = value
    }
    emitValue(!quietly)
  })
}

const init = async (doEmit = true) => {
  selected.value = null
  await emitValue(doEmit)
  let prefetchValue = props.modelValue

  hasDebouncedFetch.value = false

  // Fake search to fetch initial options
  fetchOptions(null, l => {}, prefetchValue).then(r => {
    if (prefetchValue) {
      if (Array.isArray(prefetchValue)) {
        const selectedOptions = []

        prefetchValue.forEach(value => {
          const option = getOptions.value.find(o => o.value === value)

          selectedOptions.push(option)
        })

        selected.value = selectedOptions
      } else {
        selected.value = getOptions.value.find(o => o.value === prefetchValue)
      }
    }

    selectFirstOption()
  })

  hasDebouncedFetch.value = props.debounced
}

const unselect = (value = null) => {
  if (value) {
    selected.value = selected.value.filter(s => {
      return s.value !== value
    })
  } else {
    selected.value = null
  }
  emitValue()
}

onMounted(() => {
  init(false)
})

const getOptions = computed(() => {
  let options = JSON.parse(JSON.stringify(props.options))

  // Transform all options
  if (props.transform) {
    options = options.map(option => {
      return props.transform(option)
    })
  }

  // Add selected option if missing
  if (selected.value) {
    const selectedValues = props.multiple ? selected.value : [selected.value]

    selectedValues.forEach(selectedValue => {
      const index = options.findIndex(o => {
        if (Object.hasOwn(selectedValue, 'value')) return o.value === selectedValue.value
        else return o.value === selectedValue
      })

      if (index < 0) {
        // Selected should be already transformed.
        options.push(selectedValue)
      }
    })
  }

  return options
})

const emitValue = (doEmit = true) => {
  let returnValue = null
  if (selected.value) {
    if (props.multiple) {
      returnValue = selected.value.map(v => v.value)
    } else {
      returnValue = selected.value.value
    }
  }

  if (doEmit) {
    emit('update:modelValue', returnValue)
    emit('input')
    emit('search', returnValue)
  }
}

const deselected = () => {
  emit('deselected')
}

const selectFirstOption = () => {
  if (props.selectFirst) {
    const option = getOptions.value.length ? getOptions.value[0] : undefined

    if (typeof option !== 'undefined') {
      if (props.multiple) {
        selected.value = [option]
      } else {
        selected.value = option
      }

      if (props.emitOnSelectFirst) {
        emitValue()
      }
    }
  }
}

const filterBy = (option, label, search) => {
  return $global.toSearchStr(label || '').indexOf($global.toSearchStr(search)) > -1
}

watch(selected, (newValue, oldValue) => {
  if (newValue === null && oldValue !== null) {
    emit('deselected')
    emitValue()
  }
})

watch(
  () => getOptions.value,
  (value, oldValue) => {
    if (oldValue.length === 0) {
      selectFirstOption()
    }
  }
)

defineExpose({ selected, unselect, setSelected, init })
</script>

<template>
  <vue-select
    v-model="selected"
    :aria-label="ariaLabel"
    :options="getOptions"
    :components="{ OpenIndicator, Deselect }"
    :multiple="multiple"
    v-bind="props.taggable ? { taggable: props.taggable } : { filterable: props.filterable() }"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    :create-option="createOption"
    :selectable="selectable"
    :filter-by="filterBy"
    @option:selected="emitValue"
    @option:deselected="emitValue"
    @option:deselecting="deselected"
    @search="fetchOptions"
  >
    <!-- Pass whole slot to v-select -->
    <template #option="option">
      <slot name="option" v-bind="option" />
      <span
        v-if="loading && loadingFromUrl"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
    </template>

    <template #no-options="{ search, searching }">
      <template v-if="searching">
        <template v-if="loading && loadingFromUrl">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
        </template>
        <template v-else>
          {{ $_('components/search_select.no_results', 'Žádný výsledek pro ') }}
          <em>{{ search }}</em>
          .
        </template>
      </template>
      <em v-else style="opacity: 0.5">
        {{ $_('components/search_select.hint', 'Začni psát pro vyhledávání.') }}
      </em>
    </template>
    <template v-if="hideSelected" #selected-option-container>
      <span class="d-none">{{ placeholder }}</span>
    </template>
    <template #search="{ attributes, events }">
      <input
        class="vs__search"
        v-bind="attributes"
        :placeholder="placeholder"
        :required="required && !selected"
        v-on="events"
      />
    </template>
    <template #selected-option="selectedOption">
      <slot name="selected-option" v-bind="selectedOption" />
    </template>
    <template #list-header="{ search, loading, searching, filteredOptions }">
      <slot name="list-header" v-bind="{ search, loading, searching, filteredOptions }" />
    </template>
  </vue-select>
</template>

<style lang="scss">
.v-select {
  height: 100%;
  width: 100%;

  .vs__dropdown-toggle {
    padding: unset;
    border: unset;
    border: 2px solid #ebecec;
    height: 100%;
  }

  .vs__selected-options {
    padding: 0.375rem 0.8rem 0.375rem 0.65rem;
  }

  .vs__selected {
    margin: 0 0 0 5px !important;
    border: unset;

    // I dont know when there is two options on same row ...
    &:not(:first-child) {
      margin: 2px 0 0 5px !important;
    }
  }

  .vs__actions {
    position: relative;
    padding: 10px;
    background: #f9f9f9;
    border-left: 2px solid #ebecec;
    cursor: pointer;

    .vs__spinner {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border-top: 0.5em solid rgba(100, 100, 100, 0.1);
      border-right: 0.5em solid rgba(100, 100, 100, 0.1);
      border-bottom: 0.5em solid rgba(100, 100, 100, 0.1);
      border-left: 0.5em solid rgba(60, 60, 60, 0.45);
    }
  }

  .vs__search {
    margin: unset;
    font-weight: 600;
    color: #808285;
    font-size: 0.875rem;
  }

  &.vs--single {
    .vs__clear {
      fill: #333;
      position: absolute;
      left: -23px;
      top: 6px;
    }
  }

  &.vs--multiple {
    .vs__selected {
      margin: 0 0 0 5px;
      border: unset;
      color: #ffffff;
      background-color: #999999;
      padding: 0 0.5rem;
      font-size: 0.85rem;
    }

    .vs__deselect {
      margin-top: 2px;
      margin-left: 5px;
      fill: #ffffff;
    }
  }
}
</style>
